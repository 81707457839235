<template>
  <v-app>
    <v-app-bar
      app
      color="blue-grey darken-2"
      dark
    >
       
      <div class="d-flex align-center">

        <a href="https://seijikojima.com">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://seijikojima.com/cactus.png"
          transition="scale-transition"
          width="50"
        />
        </a>
       
        <router-link to="/" class="home">Todo List</router-link>
      </div>


      <v-spacer></v-spacer>

      <div style="margin-right: 10px"> {{this.$store.state.email}}</div>

      <v-btn style="margin-right: 10px" @click="logout">
        LOGOUT
      </v-btn>

      <v-btn color="lime accent-3">
        <router-link to="/login" class="login">Login</router-link>
      </v-btn>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      message : ''
    }
  },
  methods: {
    logout()  {
      const token = this.$cookies.get('jwt')
      this.axios.get(process.env.VUE_APP_APIURL + '/logout', { 
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then( () => {
          this.$store.state.userId = null
          this.$store.state.email = null
          this.$store.state.login = false
          this.$cookies.set("jwt", '' , "1ms", "/")
          this.$router.push('/', (err) => {
          console.log(err)
        })
      })
      .catch((e) => {
        this.message = e
      })
    }
  }

};
</script>

<style scoped>

/* .body {
  background-color : #000;
} */

.home {
  color: white;
}

.login{
  color : darkslategrey;
} 

a {
  text-decoration: none;
}

</style>